<template>
  <form-wizard
    id="wizard"
    class="wizard wizard-primary sw sw-theme-default sw-justified"
    color="#3f80ea"
    @on-complete="save(true)"
    @on-save="save(false)"
    :hideSaveButton="isNew"
    :finishButtonText="isNew ? 'Create' : 'Save & Exit'"
  >
    <tab-content title="Details" description="Specify configuration details">
      <form>
        <b-form-group label="Name">
          <b-form-input
            type="text"
            placeholder="e.g. My New Schema"
            v-model="schema.name"
            required
            trim
          />
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            v-model="schema.description"
            placeholder="e.g. This is a description of my new schema..."
            rows="4"
            max-rows="4"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Type">
          <b-form-radio-group
            v-model="schema.type"
            :options="[
              { value: 'fake', text: 'Fake Data' },
              { value: 'stored', text: 'Stored & Fake Data' },
            ]"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group label="Data Source" v-if="schema.type === 'stored'">
          <b-form-select
            v-model="schema.source"
            :options="sourceList"
          ></b-form-select>
        </b-form-group>
      </form>
    </tab-content>
    <tab-content title="Fields" description="Customize field layout">
      <b-container>
        <b-row>
          <b-col>
            <b-card class="field-card">
              <b-card-title>
                Fields
                <b-button
                  size="sm"
                  variant="primary"
                  class="float-right card-title-button"
                  @click="addField"
                  >Add Field</b-button
                >
              </b-card-title>
              <b-table
                striped
                :items="schema.fields"
                :fields="fields"
                class="no-body-margin"
                tbody-tr-class="cursor-pointer"
                v-on:row-clicked="editField"
              >
                <template #cell(select)="data">
                  <b-form-checkbox v-model="selectedFields" :value="data.index">
                  </b-form-checkbox>
                </template>
                <template #cell(name)="data">
                  <b-form-input
                    v-model="data.item.name"
                    placeholder="e.g. Field 1"
                    @click="editField(data.item)"
                  ></b-form-input>
                </template>
                <template #cell(type)="data">
                  <b-form-select
                    v-model="data.item.type"
                    :options="types"
                  ></b-form-select>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1"
                    @click="editField(data.item)"
                    ><i class="fas fa-edit"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteField(data.index)"
                    ><i class="far fa-trash-alt"></i
                  ></b-button>
                </template>
              </b-table>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="field-card">
              <b-card-title>
                Options
                <span class="float-right font-italic">
                  {{ fieldType }}
                  <span v-if="fieldType">
                    <b-button
                      variant="success"
                      size="sm"
                      title="Save Changes"
                      v-b-tooltip.hover.bottom
                      class="ml-2 mr-1"
                      @click="saveField"
                      ><i class="far fa-save"></i
                    ></b-button>
                    <b-button
                      variant="warning"
                      size="sm"
                      title="Revert Changes"
                      v-b-tooltip.hover.bottom
                      @click="revertField"
                      ><i class="fas fa-ban"></i
                    ></b-button>
                  </span>
                </span>
              </b-card-title>
              <b-card-text v-if="!fieldType">
                <b-alert variant="info" show
                  ><div class="alert-message">
                    <i class="fas fa-exclamation-circle"></i> Please select a
                    field.
                  </div></b-alert
                >
              </b-card-text>
              <b-card-text v-if="field.type === 'fake'">
                <b-row>
                  <b-col>
                    <b-form-group label="Generator">
                      <b-form-input
                        v-model="field.method.name"
                        disabled
                        placeholder="Select a generator below..."
                      />
                    </b-form-group>
                  </b-col>
                  <!-- <b-col>
                    <b-form-group label="% Blank" class="ml-1">
                      <b-form-input
                        type="number"
                        v-model="field.blank"
                        placeholder="0"
                      />
                    </b-form-group>
                  </b-col> -->
                </b-row>
              </b-card-text>
              <b-tabs
                pills
                v-if="field.type === 'fake'"
                class="fake-method-tabs"
              >
                <b-tab
                  :title="item"
                  active
                  v-for="(item, index) in Object.keys(fakerMethods)"
                  :key="index"
                >
                  <b-card-text>
                    <small class="ml-2 font-italic">{{
                      fakerMethods[item].description
                    }}</small>
                    <b-list-group flush>
                      <b-list-group-item
                        v-for="(method, index) in fakerMethods[item].methods"
                        :key="index"
                      >
                        <b-button
                          variant="link"
                          class="list-method"
                          @click="
                            selectMethod(
                              item + '.' + method.name,
                              fakerMethods[item].name + '.' + method.method
                            )
                          "
                        >
                          {{ method.name }}
                        </b-button>
                        <span class="float-right font-italic">
                          {{ method.example }}
                        </span>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card-text>
                </b-tab>
              </b-tabs>

              <b-card-text v-if="field.type === 'stored'">
                <b-row>
                  <b-col>
                    <b-form-group label="Field">
                      <b-form-select
                        :options="sourceFieldList"
                        v-model="field.value"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>

              <b-card-text class="mt-3 mb-0" v-if="field.type === 'formula'">
                <small class="text-muted">
                  Write custom formula using JavaScript. Value in $field.value
                  will be used to populate schema field.
                </small>
                <prism-editor
                  v-model="field.formula"
                  language="js"
                  line-numbers
                  :highlight="highlighter"
                  class="prism-editor mt-1"
                ></prism-editor>
              </b-card-text>
              <!--<b-list-group flush v-if="field.type === 'formula'">
                <b-list-group-item>
                  <b-button
                    v-b-toggle="'formula-collapse-vars'"
                    class="m-1 list-header-button"
                  >
                    Variables
                    <small class="ml-2 font-italic"
                      >System variables available for use</small
                    >
                    <i
                      class="fas fa-angle-up float-right mt-1"
                      v-if="varVisible"
                    ></i>
                    <i
                      class="fas fa-angle-down float-right mt-1"
                      v-if="!varVisible"
                    ></i>
                  </b-button>
                  <b-collapse id="formula-collapse-vars" v-model="varVisible">
                    <b-list-group flush>
                      <b-list-group-item
                        v-for="(variable, index) in variables"
                        :key="index"
                      >
                        <b-row>
                          <b-col cols="3">
                            <b-button
                              variant="link"
                              class="list-method"
                              @click="selectMethod(item + '.' + variable.name)"
                            >
                              {{ variable.name }}
                            </b-button>
                            <small class="text-muted mt-1 font-italic">
                              {{ variable.description }}
                            </small>
                          </b-col>
                          <b-col>
                            <b-list-group flush>
                              <b-list-group-item
                                v-for="(
                                  property, jindex
                                ) in variable.properties"
                                :key="jindex"
                                class="py-1"
                              >
                                <b>{{ property.name }}</b
                                >&nbsp;
                                <i
                                  >({{ property.type }},
                                  {{ property.mutable }})</i
                                >
                                <br /><small>{{ property.description }}</small>
                              </b-list-group-item>
                            </b-list-group>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </b-list-group-item>
                <b-list-group-item>
                  <b-button
                    v-b-toggle="'formula-collapse-funcs'"
                    class="m-1 list-header-button"
                  >
                    Functions
                    <small class="ml-2 font-italic"
                      >Functions available for use</small
                    >
                    <i
                      class="fas fa-angle-up float-right mt-1"
                      v-if="funcVisible"
                    ></i>
                    <i
                      class="fas fa-angle-down float-right mt-1"
                      v-if="!funcVisible"
                    ></i>
                  </b-button>
                  <b-collapse id="formula-collapse-funcs" v-model="funcVisible">
                    <b-list-group flush>
                      <b-list-group-item
                        v-for="(func, index) in functions"
                        :key="index"
                      >
                        <b-button
                          variant="link"
                          class="list-method"
                          @click="selectMethod(item + '.' + func.name)"
                        >
                          {{ func.name }}
                        </b-button>
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </b-list-group-item>
              </b-list-group>-->
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </tab-content>
  </form-wizard>
</template>

<script>
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import FormWizard from "@/components/CustomFormWizard";
import TabContent from "@/components/CustomTabContent";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "SchemaEditor",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    FormWizard,
    TabContent,
    PrismEditor,
  },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    sources: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      bind: ["slugs"],
      fieldIndex: null,
      field: {},
      fields: [
        // {
        //   key: "select",
        //   label: "",
        //   sortable: true,
        // },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: true,
        },
      ],
      types: [
        {
          text: "Fake Data",
          value: "fake",
        },
        {
          text: "Stored Data",
          value: "stored",
        },
        {
          text: "Formula",
          value: "formula",
        },
      ],
      selectedFields: [],
      fakerMethods: {
        Address: {
          name: "address",
          description: "Postal address data",
          methods: [
            {
              name: "Street Name",
              example: "Main St",
              method: "streetName",
            },
            {
              name: "Street Address",
              example: "123 Main St",
              method: "streetAddress",
            },
            {
              name: "Street Suffix",
              example: "St",
              method: "streetSuffix",
            },
            {
              name: "Street Prefix",
              example: "N",
              method: "streetPrefix",
            },
            {
              name: "Secondary Address",
              example: "Apt. 2",
              method: "secondaryAddress",
            },
            {
              name: "City",
              example: "Seattle",
              method: "city",
            },
            {
              name: "City Prefix",
              example: "Seattle",
              method: "cityPrefix",
            },
            {
              name: "City Suffix",
              example: "Seattle",
              method: "citySuffix",
            },
            {
              name: "State",
              example: "Washington",
              method: "state",
            },
            {
              name: "State Abbreviation",
              example: "WA",
              method: "stateAbbr",
            },
            {
              name: "Zip Code",
              example: "98101",
              method: "zipCode",
            },
            {
              name: "Zip Code By State",
              example: "98101",
              method: "zipCodeByState",
            },
            {
              name: "County",
              example: "King County",
              method: "county",
            },
            {
              name: "Country",
              example: "United States of America",
              method: "country",
            },
            {
              name: "Country Code",
              example: "USA",
              method: "countryCode",
            },
            {
              name: "Latitude",
              example: "USA",
              method: "latitude",
            },
            {
              name: "Longitude",
              example: "USA",
              method: "longitude",
            },
            {
              name: "Direction",
              example: "West",
              method: "direction",
            },
            {
              name: "Cardinal Direction",
              example: "North",
              method: "cardinalDirection",
            },
            {
              name: "Ordinal Direction",
              example: "Southwest",
              method: "ordinalDirection",
            },
            {
              name: "Nearby GPS Coordinate",
              example: "['-7.1708','-14.7020']",
              method: "nearbyGPSCoordinate",
            },
            {
              name: "Timezone",
              example: "America/New_York",
              method: "timeZone",
            },
          ],
        },
        Animal: {
          name: "animal",
          description: "Animal species data",
          methods: [
            {
              name: "Dog",
              example: "Koolie",
              method: "dog",
            },
            {
              name: "Cat",
              example: "Ocicat",
              method: "cat",
            },
            {
              name: "Snake",
              example: "Black-tailed horned pit viper",
              method: "snake",
            },
            {
              name: "Bear",
              example: "Spectacled bear",
              method: "bear",
            },
            {
              name: "Lion",
              example: "Northeast Congo Lion",
              method: "lion",
            },
            {
              name: "Cetacean",
              example: "Humpback whale",
              method: "cetacean",
            },
            {
              name: "Horse",
              example: "Belgian Horse",
              method: "horse",
            },
            {
              name: "Bird",
              example: "Montezuma Quail",
              method: "bird",
            },
            {
              name: "Cow",
              example: "Highland cattle",
              method: "cow",
            },
            {
              name: "Fish",
              example: "Short mackerel",
              method: "fish",
            },
            {
              name: "Crocodilia",
              example: "Yacare Caiman",
              method: "crocodilia",
            },
            {
              name: "Insect",
              example: "Red imported fire ant",
              method: "insect",
            },
            {
              name: "Rabbit",
              example: "Satin Angora",
              method: "rabbit",
            },
            {
              name: "Type",
              example: "rabbit",
              method: "type",
            },
          ],
        },
        Commerce: {
          name: "commerce",
          description: "Product and purchase data",
          methods: [
            {
              name: "Color",
              example: "red",
              method: "color",
            },
            {
              name: "Department",
              example: "Music",
              method: "department",
            },
            {
              name: "Product Name",
              example: "Gorgeous Metal Pants",
              method: "productName",
            },
            {
              name: "Price",
              example: "455.00",
              method: "price",
            },
            {
              name: "Product Adjective",
              example: "Intelligent",
              method: "projectAdjective",
            },
            {
              name: "Product Material",
              example: "Wood",
              method: "projectMaterial",
            },
            {
              name: "Product",
              example: "Main St",
              method: "product",
            },
            {
              name: "Product Description",
              example: "The beautiful range of Apple Naturalé that...",
              method: "productDescription",
            },
          ],
        },
        Company: {
          name: "company",
          description: "Business data",
          methods: [
            {
              name: "Suffixes",
              example: "['Inc','and Sons','LLC','Group']",
              method: "suffixes",
            },
            {
              name: "Company Name",
              example: "Mills - Treutel",
              method: "companyName",
            },
            {
              name: "Company Suffix",
              example: "Group",
              method: "companySuffix",
            },
            {
              name: "Catch Phrase",
              example: "Cloned eco-centric model",
              method: "catchPhrase",
            },
            {
              name: "BS",
              example: "enable innovative experiences",
              method: "bs",
            },
            {
              name: "Catch Phrase Adjective",
              example: "Front-line",
              method: "catchPhraseAdjective",
            },
            {
              name: "Catch Phrase Descriptor",
              example: "solution-oriented",
              method: "catchPhraseDescriptor",
            },
            {
              name: "Catch Phrase Noun",
              example: "algorithm",
              method: "catchPhraseNoun",
            },
            {
              name: "BS Adjective",
              example: "magnetic",
              method: "bsAdjective",
            },
            {
              name: "BS Buzz",
              example: "cultivate",
              method: "bsBuzz",
            },
            {
              name: "BS Noun",
              example: "architectures",
              method: "bsNoun",
            },
          ],
        },
        Database: {
          name: "database",
          description: "Database data",
          methods: [
            {
              name: "Column",
              example: "avatar",
              method: "column",
            },
            {
              name: "Type",
              example: "date",
              method: "type",
            },
            {
              name: "Collation",
              example: "ascii_general_ci",
              method: "collation",
            },
            {
              name: "Engine",
              example: "ARCHIVE",
              method: "engine",
            },
          ],
        },
        "Data Type": {
          name: "datatype",
          description: "Sample data types",
          methods: [
            {
              name: "Number",
              example: "78674",
              method: "number",
            },
            {
              name: "Float",
              example: "70180.74",
              method: "float",
            },
            {
              name: "DateTime",
              example: "2068-02-21T19:49:26.531Z",
              method: "datetime",
            },
            {
              name: "String",
              example: "tI6%uW4(l5",
              method: "string",
            },
            {
              name: "UUID",
              example: "bb62c83c-15f8-4f2e-9a0b-0a117d6c9c40",
              method: "uuid",
            },
            {
              name: "Boolean",
              example: "false",
              method: "boolean",
            },
            {
              name: "Hexadecimal",
              example: "0x1",
              method: "hexaDecimal",
            },
            {
              name: "JSON",
              example: '{"Y","name":"\\t05VWRb]L","prop":"f,bP:&$)Z7"}',
              method: "json",
            },
            {
              name: "Array",
              example: `[
                "-lA1$>e+4!",
                91806,
                "aC-BwDwjo",
                87603,
                "'g5(!L#_^e",
                15658,
                "u=9,QA|2ZF",
                20620,
                "4pC.OBJ&Ha",
                "7Kjy'hCu("
                ]`,
              method: "array",
            },
          ],
        },
        Date: {
          name: "date",
          description: "Sample dates",
          methods: [
            {
              name: "Past",
              example: "2021-06-08T08:16:33.763Z",
              method: "past",
            },
            {
              name: "Future",
              example: "2022-07-01T20:50:34.747Z",
              method: "future",
            },
            {
              name: "Between",
              example: "null",
              method: "between",
            },
            {
              name: "Betweens",
              example: "[null, null, null]",
              method: "betweens",
            },
            {
              name: "Recent",
              example: "2021-08-25T03:28:39.452Z",
              method: "recent",
            },
            {
              name: "Soon",
              example: "2021-08-26T06:48:02.427Z",
              method: "soon",
            },
            {
              name: "Month",
              example: "September",
              method: "month",
            },
            {
              name: "Weekday",
              example: "Monday",
              method: "weekday",
            },
          ],
        },
        Finance: {
          name: "finance",
          description: "Sample financial data",
          methods: [
            {
              name: "Account",
              example: "89047480",
              method: "account",
            },
            {
              name: "Account Name",
              example: "Checking Account",
              method: "accountName",
            },
            {
              name: "Routing Number",
              example: "369192400",
              method: "routingNumber",
            },
            {
              name: "Mask",
              example: "2845",
              method: "mask",
            },
            {
              name: "Amount",
              example: "512.70",
              method: "amount",
            },
            {
              name: "Transaction Type",
              example: "invoice",
              method: "transactionType",
            },
            {
              name: "Currency Code",
              example: "TWD",
              method: "currencyCode",
            },
            {
              name: "Currency Name",
              example: "Vatu",
              method: "currencyName",
            },
            {
              name: "Currency Symbol",
              example: "p.",
              method: "currencySymbol",
            },
            {
              name: "Bitcoin Address",
              example: "3CCXiDgeYrEkbBqdxGtK9xhVwBbwH5",
              method: "bitcoinAddress",
            },
            {
              name: "Litecoin Address",
              example: "LHSZ9YAZjMkW1o5TH8trj9S4FJ",
              method: "litecoinAddress",
            },
            {
              name: "Credit Card Number",
              example: "6304355975893231",
              method: "creditCardNumber",
            },
            {
              name: "Credit Card CVV",
              example: "353",
              method: "creditCardCVV",
            },
            {
              name: "Ethereum Address",
              example: "0x6a5ecbeddaecb2b3f89ca9b5688428826ccfe8c8",
              method: "ethereumAddress",
            },
            {
              name: "IBAN",
              example: "BE95585050480094",
              method: "iban",
            },
            {
              name: "BIC",
              example: "VHNUJOJ1ROG",
              method: "bic",
            },
            {
              name: "Transaction Description",
              example:
                "invoice transaction at Maggio - Mueller using card ending with ***0680 for SZL 722.39 in account ***25166812",
              method: "transactionDescription",
            },
          ],
        },
        Git: {
          name: "git",
          description: "Sample Git data",
          methods: [
            {
              name: "Branch",
              example: "protocol-synthesize",
              method: "branch",
            },
            {
              name: "Commit Entry",
              example:
                "commit ee8b15882b8cde6d80b79bc88b620088535e6582\\r\\nAuthor: Nichole Hansen <Terrell.Kreiger@yahoo.com>\\r\\nDate: Wed Aug 25 2021 20:53:10 GMT-0400 (Eastern Daylight Time)\\r\\n\\r\\n    connect back-end matrix\\r\\n",
              method: "commitEntry",
            },
            {
              name: "Commit Message",
              example: "transmit redundant circuit",
              method: "commitMessage",
            },
            {
              name: "Commit SHA",
              example: "e020e6d92eb710129117f67a50db9c318d7574d1",
              method: "commitSha",
            },
            {
              name: "Short SHA",
              example: "5adc8e5",
              method: "shortSha",
            },
          ],
        },
        Image: {
          name: "image",
          description: "Sample image urls and uris",
          methods: [
            {
              name: "Image",
              example: "http://placeimg.com/640/480/abstract",
              method: "image",
            },
            {
              name: "Avatar",
              example:
                "https://cdn.fakercloud.com/avatars/mandalareopens_128.jpg",
              method: "avatar",
            },
            {
              name: "Image Url",
              example: "http://placeimg.com/640/480",
              method: "imageUrl",
            },
            {
              name: "Abstract",
              example: "http://placeimg.com/640/480/abstract",
              method: "abstract",
            },
            {
              name: "Animals",
              example: "http://placeimg.com/640/480/animals",
              method: "animals",
            },
            {
              name: "Business",
              example: "http://placeimg.com/640/480/business",
              method: "business",
            },
            {
              name: "Cats",
              example: "http://placeimg.com/640/480/cats",
              method: "cats",
            },
            {
              name: "City",
              example: "http://placeimg.com/640/480/city",
              method: "city",
            },
            {
              name: "Food",
              example: "http://placeimg.com/640/480/food",
              method: "food",
            },
            {
              name: "Nightlife",
              example: "http://placeimg.com/640/480/nightlife",
              method: "nightlife",
            },
            {
              name: "Fashion",
              example: "http://placeimg.com/640/480/fashion",
              method: "fashion",
            },
            {
              name: "People",
              example: "http://placeimg.com/640/480/people",
              method: "people",
            },
            {
              name: "Nature",
              example: "http://placeimg.com/640/480/nature",
              method: "nature",
            },
            {
              name: "Sports",
              example: "http://placeimg.com/640/480/sports",
              method: "sports",
            },
            {
              name: "Technics",
              example: "http://placeimg.com/640/480/technics",
              method: "technics",
            },
            {
              name: "Transport",
              example: "http://placeimg.com/640/480/transport",
              method: "transport",
            },
            {
              name: "Data Uri",
              example:
                "data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20baseProfile%3D%22full%22%20width%3D%22undefined%22%20height%3D%22undefined%22%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22grey%22%2F%3E%3Ctext%20x%3D%22NaN%22%20y%3D%22NaN%22%20font-size%3D%2220%22%20alignment-baseline%3D%22middle%22%20text-anchor%3D%22middle%22%20fill%3D%22white%22%3Eundefinedxundefined%3C%2Ftext%3E%3C%2Fsvg%3E",
              method: "dataUri",
            },
          ],
        },
        Internet: {
          name: "internet",
          description: "Internet data",
          methods: [
            {
              name: "Avatar",
              example: "https://cdn.fakercloud.com/avatars/nicollerich_128.jpg",
              method: "avatar",
            },
            {
              name: "Email",
              example: "Joel_Zboncak@gmail.com",
              method: "email",
            },
            {
              name: "Example Email",
              example: "Roderick48@example.com",
              method: "exampleEmail",
            },
            {
              name: "User Name",
              example: "Deondre_Kihn49",
              method: "userName",
            },
            {
              name: "Protocol",
              example: "https",
              method: "protocol",
            },
            {
              name: "HTTP Method",
              example: "PATCH",
              method: "httpMethod",
            },
            {
              name: "URL",
              example: "https://waylon.com",
              method: "url",
            },
            {
              name: "Domain Name",
              example: "johnathan.org",
              method: "domainName",
            },
            {
              name: "Domain Suffix",
              example: "name",
              method: "domainSuffix",
            },
            {
              name: "Domain Word",
              example: "forrest",
              method: "domainWord",
            },
            {
              name: "IPv4",
              example: "206.95.129.90",
              method: "ip",
            },
            {
              name: "IPv6",
              example: "8518:82c0:9d2c:16f0:012a:5c80:3599:6e12",
              method: "ipv6",
            },
            {
              name: "Port",
              example: 52656,
              method: "port",
            },
            {
              name: "User Agent",
              example:
                "Mozilla/5.0 (Windows NT 5.0; WOW64; rv:11.4) Gecko/20100101 Firefox/11.4.5",
              method: "userAgent",
            },
            {
              name: "Color",
              example: "#074e6f",
              method: "color",
            },
            {
              name: "MAC",
              example: "75:8b:31:7c:d7:b6",
              method: "mac",
            },
            {
              name: "Password",
              example: "NppGKo82Tx9ZSi8",
              method: "password",
            },
          ],
        },
        Lorem: {
          name: "lorem",
          description: "Lorem Ipsum fake text",
          methods: [
            {
              name: "Word",
              example: "sit",
              method: "word",
            },
            {
              name: "Words",
              example: "dolore optio sapiente",
              method: "words",
            },
            {
              name: "Sentence",
              example: "Quidem aut sapiente.",
              method: "sentence",
            },
            {
              name: "Slug",
              example: "quas-nulla-reiciendis",
              method: "slug",
            },
            {
              name: "Sentences",
              example:
                "Qui sequi quo. Laudantium laudantium ducimus repellat nihil omnis quia quos. Doloremque molestias sunt ipsum consectetur maiores rerum fugiat. Adipisci rerum cumque non sint voluptatem.",
              method: "sentences",
            },
            {
              name: "Paragraph",
              example:
                "Cupiditate sint sed. Nemo cupiditate mollitia non incidunt consequuntur. Doloremque nisi harum cumque cumque. A sunt rerum autem quisquam modi occaecati accusamus aut.",
              method: "paragraph",
            },
            {
              name: "Paragraphs",
              example:
                "Voluptatem officiis rem. Ratione cum hic sunt quos temporibus iure ut et. Magni dolorem rerum qui. Maxime dolore possimus optio debitis. Sapiente et enim. Dolorum laboriosam quia earum ea.\\n \\rEt minus repellendus incidunt. Quam aperiam culpa ipsa iste vitae. Optio beatae eligendi. Similique est similique nulla a veritatis. At id itaque quae.\\n \\rLaboriosam possimus et recusandae et et sed fugit provident sit. Rerum magnam voluptatem voluptas alias. Repellendus cupiditate non aperiam.",
              method: "paragraphs",
            },
            {
              name: "Text",
              example: "ut",
              method: "text",
            },
            {
              name: "Lines",
              example: "Sunt iste illo.\\nEst libero quia odio doloribus.",
              method: "lines",
            },
          ],
        },
        Music: {
          name: "music",
          description: "Music genres",
          methods: [
            {
              name: "Genre",
              method: "genre",
              example: "Metal",
            },
          ],
        },
        Name: {
          name: "name",
          description: "Person data",
          methods: [
            {
              name: "First Name",
              method: "firstName",
              example: "Chyna",
            },
            {
              name: "Last Name",
              method: "lastName",
              example: "Feil",
            },
            {
              name: "Middle Name",
              method: "middleName",
              example: "b",
            },
            {
              name: "Full Name",
              method: "findName",
              example: "Ross Larkin",
            },
            {
              name: "Job Title",
              method: "jobTitle",
              example: "International Program Liaison",
            },
            {
              name: "Gender",
              method: "gender",
              example: "Female to male trans man",
            },
            {
              name: "Prefix",
              method: "prefix",
              example: "Ms.",
            },
            {
              name: "Suffix",
              method: "suffix",
              example: "MD",
            },
            {
              name: "Title",
              method: "title",
              example: "Product Data Executive",
            },
            {
              name: "Job Descriptor",
              method: "jobDescriptor",
              example: "Senior",
            },
            {
              name: "Job Area",
              method: "jobArea",
              example: "Configuration",
            },
            {
              name: "Job Type",
              method: "jobType",
              example: "Supervisor",
            },
          ],
        },
        Phone: {
          name: "phone",
          description: "Sample phone number data",
          methods: [
            {
              name: "Phone Number",
              method: "phoneNumber",
              example: "382.983.4927 x98573",
            },
            {
              name: "Phone Number Format",
              method: "phoneNumberFormat",
              example: "231-458-6813",
            },
            {
              name: "Phone Formats",
              method: "phoneFormats",
              example: "!##.!##.#### x#####",
            },
          ],
        },
        Random: {
          name: "random",
          description: "Random data elements",
          methods: [
            {
              name: "Array Element",
              method: "arrayElement",
              example: "a",
            },
            {
              name: "Array Elements",
              method: "arrayElements",
              example: ["b"],
            },
            {
              name: "Object Element",
              method: "objectElement",
              example: "bar",
            },
            {
              name: "Word",
              method: "word",
              example: "Cotton",
            },
            {
              name: "Words",
              method: "words",
              example: "Profound",
            },
            {
              name: "Image",
              method: "image",
              example: "http://placeimg.com/640/480/technics",
            },
            {
              name: "Locale",
              method: "locale",
              example: "cz",
            },
            {
              name: "Alpha",
              method: "alpha",
              example: "a",
            },
            {
              name: "Alphanumeric",
              method: "alphaNumeric",
              example: "n",
            },
          ],
        },
        System: {
          name: "system",
          description: "Sample system info",
          methods: [
            {
              name: "File Name",
              method: "fileName",
              example: "toys_strategist_berkshire.ktx",
            },
            {
              name: "Common File Name",
              method: "commonFileName",
              example: "bypass.pdf",
            },
            {
              name: "Mime Type",
              method: "mimeType",
              example: "application/vnd.renlearn.rlprint",
            },
            {
              name: "Common File Type",
              method: "commonFileType",
              example: "image",
            },
            {
              name: "Common File Ext",
              method: "commonFileExt",
              example: "mp2a",
            },
            {
              name: "File Type",
              method: "fileType",
              example: "chemical",
            },
            {
              name: "File Ext",
              method: "fileExt",
              example: "3gp",
            },
            {
              name: "Directory Path",
              method: "directoryPath",
              example: "/Library",
            },
            {
              name: "File Path",
              method: "filePath",
              example: "/etc/namedb/mobile.uvd.pic",
            },
            {
              name: "SemVer",
              method: "semver",
              example: "5.2.4",
            },
          ],
        },
        Time: {
          name: "time",
          description: "Sample time",
          methods: [
            {
              name: "Recent",
              method: "recent",
              example: 1629990472011,
            },
          ],
        },
        Vehicle: {
          name: "vehicle",
          description: "Sample vehicle data",
          methods: [
            {
              name: "Vehicle",
              method: "vehicle",
              example: "Honda Accord",
            },
            {
              name: "Manufacturer",
              method: "manufacturer",
              example: "Jaguar",
            },
            {
              name: "Model",
              method: "model",
              example: "Model T",
            },
            {
              name: "Type",
              method: "type",
              example: "Sedan",
            },
            {
              name: "Fuel",
              method: "fuel",
              example: "Hybrid",
            },
            {
              name: "VIN",
              method: "vin",
              example: "W0E629VWL5H383449",
            },
            {
              name: "Color",
              method: "color",
              example: "turquoise",
            },
            {
              name: "VRM (License Plate)",
              method: "vrm",
              example: "MR14VPV",
            },
            {
              name: "Bicycle",
              method: "bicycle",
              example: "b",
            },
          ],
        },
      },
      variables: [
        {
          name: "$field",
          description: "Current field",
          properties: [
            {
              name: "name",
              type: "String",
              mutable: "Immutable",
              description: "Field name",
            },
            {
              name: "type",
              type: "String",
              mutable: "Immutable",
              description: "Field type (fake, stored, formula)",
            },
            {
              name: "value",
              type: "String",
              mutable: "Mutable",
              description: "Field value",
            },
          ],
        },
        {
          name: "$project",
          description: "Current project",
          properties: [
            {
              name: "name",
              type: "String",
              mutable: "Immutable",
              description: "Project name",
            },
            {
              name: "path",
              type: "String",
              mutable: "Immutable",
              description: "Project path (e.g. my-new-project)",
            },
            {
              name: "description",
              type: "String",
              mutable: "Immutable",
              description: "Product description",
            },
          ],
        },
        {
          name: "$schema",
          description: "Current schema",
          properties: [
            {
              name: "name",
              type: "String",
              mutable: "Immutable",
              description: "Schema name",
            },
            {
              name: "description",
              type: "String",
              mutable: "Immutable",
              description: "Schema description",
            },
            {
              name: "type",
              type: "String",
              mutable: "Immutable",
              description: "Schema type (fake, stored)",
            },
          ],
        },
      ],
      varVisible: false,
      functions: [
        {
          name: "Data",
        },
        {
          name: "Date",
        },
        {
          name: "DateTime",
        },
        {
          name: "Math",
        },
        {
          name: "Number",
        },
        {
          name: "String",
        },
      ],
      funcVisible: false,
    };
  },
  computed: {
    fieldType() {
      switch (this.field.type) {
        case "fake":
          return "Fake Data";
        case "stored":
          return "Stored Data";
        case "formula":
          return "Formula";
        default:
          return "";
      }
    },
    sourceList() {
      return this.sourceOptions(this.sources);
    },
    sourceFieldList() {
      if (this.schema.source) {
        const sourceIndex = this.getIndex(this.sources, this.schema.source);
        if (sourceIndex !== null) {
          const fields = this.sources[sourceIndex].fields;
          return Array.isArray(fields)
            ? this.sourceOptions(fields, "name")
            : [];
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    isNew() {
      return !("path" in this.schema);
    },
  },
  watch: {
    schema: {
      immediate: true,
      handler(schema) {
        if (!("fields" in schema) || !Array.isArray(schema.fields)) {
          this.schema.fields = [];
        }
      },
    },
  },
  methods: {
    save(exit = false) {
      if (exit) {
        this.$emit("save-and-exit", this.schema);
      } else {
        this.$emit("save", this.schema);
      }
    },
    addField() {
      const field = { type: "fake", method: {} };
      const index = this.schema.fields.length;
      const fields = [...this.schema.fields, field];
      this.$set(this.schema, "fields", fields);
      this.editField(field, index);
    },
    deleteField(index) {
      let fields = [...this.schema.fields];
      fields.splice(index, 1);
      this.$set(this.schema, "fields", fields);
    },
    editField(item, index) {
      console.log("Field", item, index);
      this.fieldIndex = index;
      this.field = { ...item, method: { ...item.method } };
    },
    saveField() {
      let field = { ...this.schema.fields[this.fieldIndex], ...this.field };
      this.$set(this.schema.fields, this.fieldIndex, field);
      this.notyf.success("Field saved successfully.");
    },
    revertField() {
      this.field = { ...this.schema.fields[this.fieldIndex] };
      this.notyf.success("Field reverted successfully.");
    },
    selectMethod(name, method = null) {
      console.log("Method", name, method);
      if (method !== null) {
        this.$set(this.field, "method", { name: name, method: method });
      } else {
        this.$set(this.field, "method", { name: name, method: name });
      }
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
  },
};
</script>

<style lang="css" scoped>
.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding-top: 0;
  padding-bottom: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
}

.fake-method-tabs {
  max-height: 500px;
  overflow-y: auto;
}
</style>
